<template>
	 <div class="form-list">
        <div class="form-item">
            <span class="hint">姓名</span>
            <input type="text" class="value readonly" :value="formData.clientname" readonly>
        </div>
        <div class="form-item">
            <span class="hint">性別</span>
            <div class="value">
                <span :class="{'gender': true, 'current': formData.clientsex==2}" @click="changeFormData('clientsex', 2)">女</span>
                <span :class="{'gender': true, 'current': formData.clientsex==1}" @click="changeFormData('clientsex', 1)">男</span>
            </div>
        </div>
        <div class="form-item">
            <span class="hint">證件號碼</span>
            <input type="text" class="value readonly" :value="formData.clientidcard" readonly>
        </div>
        <div class="form-item">
            <span class="hint">出生日期</span>
            <div class="value" v-text="formData.clientbirthday" @click="show = true"></div>
            <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
			<van-popup
				v-model="show"
				position="bottom"
				:style="{ height: '30%' }"
			>
				<van-datetime-picker
					v-model="currentDate"
					type="date"
					title="选择出生日期"
					:min-date="minDate"
					:max-date="maxDate"
					@confirm="onDateTimePickConfirm"
				/>
			</van-popup>
        </div>
        <div class="form-item">
            <span class="hint">聯繫電話</span>
            <input type="text" class="value readonly" :value="formData.clientmobile" readonly>
        </div>
        <div class="form-item">
            <span class="hint">郵箱</span>
            <input type="text" class="value" v-model="formData.clientemail">
        </div>
        <div class="form-item">
            <span class="hint">選擇地區</span>
            <div class="value" v-text="areaText" @click="showArea"></div>
            <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-arrow">
			<van-popup
				v-model="showAreaModal"
				position="bottom"
				:style="{ height: '30%' }"
			>
				<van-picker @cancel="showAreaModal=false" @confirm="onAreaConfirm" value-key="codedesc" show-toolbar :columns="columns" :loading="areaLoading" @change="onAreaChange"/>
			</van-popup>
        </div>
		<div class="form-item">
            <span class="hint">具體地址</span>
            <input type='input' class="value" v-model="formData.clientaddress"/>
        </div>
		<div class="btn-save" @click="submit"><div class="lds-ring1" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在保存...' : '保存信息'}}</div>
    </div>
</template>

<script>
	import moment from 'moment'
	import user_info from '@/views/mixins/user_info'
	export default {
		mixins: [
			user_info,
		],
		data: function(){
			return {
				preCodeid: undefined,
				showAreaModal: false,
				show: false,
				areaText: '',
				areaLoading: false,
				columns: [{values: []}, {values: []}],
                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),
                currentDate: new Date(),
			}
		},
		created() {
			if(this.$store.getters.userInfo && this.$store.getters.userInfo.clientbirthday) {
				this.currentDate = new Date(this.$store.getters.userInfo.clientbirthday)
			}
			// console.log(this.$store.getters.clientId)
			// this.$store.dispatch('getUserInfo', {clientId: this.$store.getters.clientId}).then((rs)=>{
            //     console.log('getUserInfo',rs)
            // })
		},
		methods: {
			moment,
			onDateTimePickConfirm(d){
				this.show = false
                var month = '' + (d.getMonth() + 1)
                var day = '' + d.getDate()
                var year = d.getFullYear()
                if (month.length < 2)
                    month = '0' + month
                if (day.length < 2)
                    day = '0' + day;
                var formData = {...this.formData}
                formData.clientbirthday = [year, month, day].join('-')
                this.formData = formData
			},
			submit: function(){
				this.formData.clientbirthday = this.moment(this.currentDate).format("YYYY-MM-DD")
				this.updateUserInfo((rs, error)=>{
					if(error) {
						this._toast.warning(this, error)
					} else {
						this._toast.success(this, '用户信息修改成功')
					}
				})
			},
			onAreaConfirm: function(values){
				this.showAreaModal = false
				if(values[0] && values[1]) {
					this.changeFormData('arealevel1id', values[0].codeid)
					this.changeFormData('arealevel2id', values[1].codeid)
					this.areaText = values[0].codedesc+'/'+values[1].codedesc
				}
			},
			showArea: function(){
				this.showAreaModal = true
				this.areaLoading = true
				this.getAreaData('B5', undefined, (rs, error)=>{
					this.areaLoading = false
					this.columns[0].values = rs || []
					if(this.columns[0].values.length && !this.columns[1].values.length) {
						this.getAreaData('B5', this.columns[0].values[0].codeid, (rs, error)=>{
							this.columns[1].values = rs || []
						})
					}
				})
			},
			onAreaChange(picker, values, index) {
				if(this.preCodeid != values[0].codeid) {
					this.preCodeid = values[0].codeid
					this.columns[1].values = []
					this.getAreaData('B5', values[0].codeid, (rs, error)=>{
						this.columns[1].values = rs || []
					})
				}
			},
			onConfirm: function(date){
				this.show = false
				this.changeFormData('clientbirthday', this.formatDate(date))
			},
			formatDate(date) {
				var year = date.getFullYear()
				var month = date.getMonth()+1
				var day = date.getDate()
				return `${year}-${month<10 ? ('0'+month) : month}-${day<10 ? ('0'+day) : day}`;
			},
		}
		
	}
</script>

<style lang="scss" scoped>
	.form-list {
		display: flex;
		flex-direction: column;
		padding-top: 16px;
	}

	.form-list > .form-item {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		align-items: center;
		padding: 0px 24px 0px 24px;
		position: relative;
	}

	.form-list > .form-item > .hint {
		color: #969696;
		font-size: 28px;
		font-weight: 400;
		position: absolute;
		left: 24px;
		top: 50%;
		transform: translateY(-50%);
	}

	.form-list > .form-item > .value {
		flex: 1;
		height: 97px;
		color: #231F20;
		font-weight: bold;
		font-size: 32px;
		text-align: right;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		box-sizing: border-box;
		position: relative;
		background: transparent;
		border-bottom: 1px solid #B7B7B7;
	}

	.form-list > .form-item > .icon-arrow {
		width: 17px;
		height: 34px;
	}

	.form-list > .form-item .gender {
		display: inline-block;
		width: 100px;
		height: 64px;
		background-color: #EDEDED;
		color: #231F20;
		font-size: 32px;
		line-height: 64px;
		text-align: center;
	}

	.form-list > .form-item .gender.current {
		color: #fff;
		background-color: #36C4D0;
	}

	.form-list > .form-item:nth-child(7),
	.form-list > .form-item:nth-child(5) {
		margin-top: 16px;
	}

	.form-list > .form-item:nth-child(6) .value,
	.form-list > .form-item:nth-child(8) .value,
	.form-list > .form-item:nth-child(4) .value {
		border-bottom: none;
	}

	.form-list > .form-item > .value.readonly,
	.form-list > .form-item input::placeholder,
	.form-list > .form-item textarea::placeholder {
		color: #969696;
		font-weight: 400;
		font-size: 28px;
		line-height: 26px;
	}

	.form-list > .btn-save {
		width: 420px;
		height: 88px;
		border-radius: 44px;
		background-color: #EDEDED;
		border: 1px solid #36C4D0;
		margin: 48px auto 0px auto;
		font-size: 34px;
		font-weight: bold;
		color: #36C4D0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.form-list > .btn-save:active {
		opacity: 7;
	}

	/** loading start */
    .lds-ring1 {
        display: inline-block;
        position: relative;
        width: 42px;
        height: 42px;
		margin-right: 20px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 4px;
        border: 4px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 
</style>
